<template>
  <div class="contenidoFormulario" id="formulario" style="height: auto">
    <div class="container">
      <p class="title">
        <img alt="logo" loading="lazy" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/icono-1.png" />
        {{ $t('message.request_more') }} <span>{{ $t('message.information') }}</span><br> <span class="small"> </span>
      </p>
      <p class="typo__p" v-if="submitStatus === 'OK'" style="font-size: 20pt">
        {{ $t('message.thanks_for_submit') }}, {{ this.name }}!</p>
        <div v-if="submitStatus === 'OK'" style="color:white;">{{ $t('message.respond_soon') }}</div>
      <p class="typo__p" v-if="submitStatus === 'ERROR'">{{ $t('message.fill_form_correctly') }}.</p>
      <p class="typo__p" v-if="submitStatus === 'PENDING'">{{ $t('message.sending') }}...</p>
      <div class="col-lg-12 col-md-12 col-xs-12 flex-container" v-if="submitStatus == '' || submitStatus == 'ERROR' || submitStatus == 'PENDING'">
        <div class="col-md-4 col-xs-12 contactsCol">
          <div class="rowInput2" >
            <input type="text" name="nombre" :placeholder="$t('message.name') + '*'" v-model.trim="$v.name.$model" :class="[$v.name.$error ? 'form-group--error' :  [afterFormSend ? 'form-group--valid' : ''] ]">
            <span class="formError" v-if="$v.name.$dirty && !$v.name.required">{{ $t('message.name_required') }}.</span>
            <span class="formError" v-if="$v.name.$dirty && !$v.name.minLength">{{ $t('message.name_atleast') }} {{$v.name.$params.minLength.min}} {{ $t('message.letters') }}.</span>
          </div>
          <div class="rowInput2">
            <input type="text" name="apellido1" :placeholder="$t('message.first_last_name') + '*'" v-model.trim="$v.surname1.$model" :class="[$v.surname1.$error ? 'form-group--error' :  [afterFormSend ? 'form-group--valid' : ''] ]">
            <span class="formError" v-if="$v.surname1.$dirty && !$v.surname1.required">{{ $t('message.surname_required') }}.</span>
          </div>
          <div class="rowInput2">
            <input type="text" name="apellido2" :placeholder="$t('message.second_last_name')" v-model.trim="$v.surname2.$model" :class="[$v.surname2.$error ? 'form-group--error' :  [afterFormSend ? 'form-group--valid' : ''] ]">
          </div>
        </div>
        <div class="col-md-4 col-xs-12 contactsCol">
          <div class="rowInput2">
            <input type="tel" name="telefono" :placeholder="$t('message.phone') + '*'" v-model.trim="$v.phone.$model" :class="[$v.phone.$error ? 'form-group--error' :  [afterFormSend ? 'form-group--valid' : ''] ]">
            <span class="formError" v-if="$v.phone.$dirty && !$v.phone.required">{{ $t('message.phone_required') }}.</span>
            <span class="formError" v-if="$v.phone.$dirty && !$v.phone.numeric || !$v.phone.minLength">{{ $t('message.phone_not_valid') }}.</span>
          </div>
          <div class="rowInput2">
            <input type="text" name="email" placeholder="Email*" v-model.trim="$v.email.$model" :class="[$v.email.$error ? 'form-group--error' :  [afterFormSend ? 'form-group--valid' : ''] ]">
            <span class="formError" v-if="$v.email.$dirty && !$v.email.required">{{ $t('message.email_required') }}.</span>
            <span class="formError" v-if="$v.email.$dirty && !$v.email.email">{{ $t('message.email_not_valid') }}.</span>
          </div>
          <div class="rowSelect3">
            <select class="select_arrow3" name="sede" v-model.trim="$v.campus.$model" :class="[$v.campus.$error ? 'form-group--error' :  [afterFormSend ? 'form-group--valid' : ''] ]">
              <option value="0" disabled selected>{{ $t('message.event_location') }}*</option>
              <option value="Mad">Madrid</option>
              <option value="Bar">Barcelona</option>
              <option value="lpa">Las Palmas</option>
              <option value="Val">Valencia</option>
              <option value="Ali">Alicante</option>
              <option value="Ovi">Oviedo</option>
              <option value="Gran">Granada</option>
              <option value="Sevil">Sevilla</option>
              <option value="Mal">Málaga</option>
              <option value="Caste">Castellón</option>
              <option value="La Rio">La Rioja</option>
              <option value="Coru">Coruña</option>
              <option value="Bilb">Bilbao</option>
              <option value="Zar">Zaragoza</option>
              <option value="Tener">Tenerife</option>
              <option value="Nav">Navarra</option>
              <option value="Cant">Cantabria</option>
              <option value="Düsse">Düsseldorf</option>
              <option value="Guat">Guatemala</option>
              <option value="MexC">Ciudad de México</option>
              <option value="Qui">Quito</option>
              <option value="Lim">Lima</option>
              <option value="Mi">Miami</option>
              <option value="NY">New York</option>


            </select>
            <span class="formError" v-if="$v.campus.$dirty && !$v.campus.campusValidate">{{ $t('message.campus_not_selected') }}.</span>
          </div>
        </div>
        <div class="col-md-4 col-xs-12 textarea contactsCol">
          <textarea rows="5" name="comentarios" :placeholder="$t('message.message')+'*'" v-model.trim="$v.message.$model" :class="[$v.message.$error ? 'form-group--error' :  [afterFormSend ? 'form-group--valid' : ''] ]"></textarea>
            <span class="formError" v-if="$v.message.$dirty && !$v.message.required">{{ $t('message.message_required') }}.</span>
        </div>

        <div class="col-lg-12 minortext" style="text-align: left;">
          <p>{{ $t('convenio.RGPD_youcan') }} <a href="https://aicad.es/aviso-legal-privacidad/">{{ $t('convenio.RGPD_policy') }}</a></p>
          <p><strong>{{ $t('convenio.RGPD_responsible_catp') }}</strong>: {{ $t('convenio.RGPD_responsible_answ') }}</p>
          <p><strong>{{ $t('convenio.RGPD_finalidad') }}</strong>: {{ $t('convenio.RGPD_info') }}</p>
          <p><strong>{{ $t('convenio.RGPD_almacenamiento') }}</strong>: {{ $t('convenio.RGPD_almacenamiento_anws') }}</p>
          <p><strong>{{ $t('convenio.RGPD_right') }}</strong>: {{ $t('convenio.RGPD_right_anws') }} <a href="https://aicad.es/page/aviso-legal-privacidad/">{{ $t('convenio.RGPD_limits') }}</a></p>
        </div>

        <div class="col-lg-12 col-md-12 col-xs-12">
          <div class="col-lg-8 contactsSendCol" style="text-align: left;">
            <input class="rowcheckbox2" type="checkbox" name="privacidad" value="1" id="c2" v-model.trim="$v.acceptTerms.$model">
            <label for="c2"><span  :class="[$v.acceptTerms.$error ? 'form-group--error terms--error' :  [afterFormSend ? 'form-group--valid' : ''] ]"></span></label>
            <p class="legal2">{{ $t('message.accept_the') }} <a target="_blank" href="https://www.aicad.es/aviso-legal-privacidad/" rel="width=568; height=420;">{{ $t('message.privacy_policy') }}</a>*</p>
            <div class="formError" v-if="$v.acceptTerms.$dirty && !$v.acceptTerms.termsValidate">{{ $t('message.must_accept_terms') }}.</div>
          </div>
          <div class="col-lg-4 contactsSendCol" style="text-align: right">
            <div class="btnEnviar formPie" style="cursor: pointer;">
              <a @click="onContactsFormSend">
                {{ $t('message.send') }}
                <img loading="lazy" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/icono-enviar.png" alt="bottom" class="img-responsive">
              </a>
            </div>
          </div>
        </div>
      </div>
      <img loading="lazy" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/arrow-bottom-form.png" alt="bottom" class="arrow-bottom-form">
    </div>
  </div>
</template>

<script>
import { required, minLength, numeric, email, boolean } from 'vuelidate/lib/validators';
import axios from 'axios';

const campusValidate = (value) => { return value != '0' }
const termsValidate = (value) => { return value }

export default {
  name: 'ContactsForm',
  data() {
    return {
      name: '',
      surname1: '',
      surname2: '',
      phone: '',
      email: '',
      campus: '0',
      message: '',
      acceptTerms: '',

      afterFormSend: false,
      submitStatus: '',
    }
  },
  validations: {
    name: {
      required,
      minLength: minLength(4)
    },
    surname1: {
      required,
    },
    surname2: {
    },
    phone: {
      required,
      numeric,
      minLength: minLength(5)
    },
    email: {
      required,
      email
    },
    campus: {
      required,
      campusValidate
    },
    message: {
      required
    },
    acceptTerms: {
      required,
      termsValidate
    }
  },
  methods: {
    onContactsFormSend() {
      this.afterFormSend = true;

      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        this.submitStatus = 'PENDING'
        let data = {
          name: this.name,
          surname1: this.surname1,
          surname2: this.surname2,
          phone: this.phone,
          email: this.email,
          campus: this.campus,
          message: this.message
        }
        axios
          .post("/email/send", data)
          .then(response => {
            if(response.status == 200) {
              this.submitStatus = 'OK'
            } else {
              this.submitStatus = 'ERROR'
            }
          }
          ).catch(error => (console.log(error), this.submitStatus = 'ERROR'));
      }
    }
  }
}
</script>

<style>
 .contenidoFormulario {
    background: #4d4d4d;
    width: 100%;
    height: 100%;
    padding: 20px 0px 20px;
    position: relative;
  }

  .contenidoFormulario .container {
    text-align: center;
  }

  .contenidoFormulario p.title {
    width: 98%;
    float: left;
    font-weight: 300;
    font-size: 30px;
    margin: 0 10px;
    padding: 20px 0;
    color: #ffffff;
    text-transform: uppercase;
    line-height: 29px;
    font-family: 'Open sans condensed', Arial, sans-serif;
    text-align: left;
  }

  .contenidoFormulario p.title span {
    font-weight: 700;
  }

  .contenidoFormulario .container .arrow-bottom-form {
    position: relative;
    bottom: -75px;
  }

  .rowInput2 {
    width: 97%;
    float: left;
    margin: 3px 0;
    font-family: 'Open sans', Arial, sans-serif!important;
    text-align: left;
  }
  
  .rowInput2 input {
    width: 100%;
    float: left;
    background: #3b3b3b;
    border: 1px solid #686868;
    padding: 10px;
    color: #ffffff;
    font-size: 14px;
  }

  .rowInput2 input::placeholder {
    color: #999;
  }

  .rowSelect3 select {
    height: auto;
    float: left;
    padding: 8px 10px;
    border: 1px solid #686868;
    width: 100%;
    overflow: hidden;
    background: url(https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/bullet_select_form_pie.gif) no-repeat right #3d3d3d;
    font-size: 14px;
    color: #ffffff;
  }

  .rowSelect3 {
    width: 97%;
    float: left;
    margin: 3px 0;
    text-align: left;
  }

  .textarea {
    text-align: left;
  }

  .textarea textarea {
    width: 97%;
    float: left;
    background: #3d3d3d;
    border: 1px solid #686868;
    padding: 9px 10px;
    color: #ffffff;
    font-size: 14px;
    margin-top: 3px;
    min-height: 137px;
  }

  .textarea textarea::placeholder {
    color: #999;
  }

  .contactsCol {
    padding: 0px;
  }

  .contactsSendCol {
    display: inline-block;
    vertical-align: top;
    margin-top: 10px;
  }

  .contenidoFormulario .legal2 {
    margin: -3px 0 0;
    color: #ffffff;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px !important;
    padding: 0;
    text-transform: none;
    display: inline-block;
  }

  .rowcheckbox2 {
    padding-left: 0;
    padding-right: 0;
    margin-top: 5px;
    margin-right: 8px;
    width: auto;
    float: left;
  }

  .contactsSendCol input[type="checkbox"] + label span {
    display: inline-block;
    width: 18px;
    height: 17px;
    background: url(https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/checkbox-off2.gif) left top no-repeat;
    margin: -1px 4px 0 0;
    vertical-align: middle;
    cursor: pointer;
  }

  .contactsSendCol input[type="checkbox"]:checked + label span {
    background: url(https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/checkbox-on2.gif) top no-repeat;
    width: 18px;
    height: 17px;
    margin-left: 0;
  }

  .contactsSendCol {
    padding-left: 0px;
  }

  .rowcheckbox2 {
    display: none;
  }

  .legal2 a {
    color: white;
    text-decoration: underline;
  }

  .legal2 a:hover {
    text-decoration: none !important;
    color: #e1c9ca !important;
  }

  .formPie {
    width: 11%;
    margin-top: 10px;
    margin-right: 12px;
  }

  .btnEnviar {
    width: 45%;
    color: #ffffff;
    font-size: 16px;
    float: right;
    background: transparent;
    margin-bottom: 6px;
  }

  .btnEnviar a {
    border: 3px solid #ffffff;
    float: left;
    width: 100%;
    padding: 7px 10px;
    text-align: left;
    color: white;
    text-decoration: none;
  }

  .btnEnviar a img {
    float: right;
    text-align: right;
    width: auto;
    margin-top: 5px;
  }

  .btnEnviar a:hover {
    transform: scale(1.05);
    transition: 0.3s ease;
  }

  .btnEnviar a {
    transition: 0.3s ease;
  }

  .titleNoticia a:hover, .titleAgenda a:hover {
    text-decoration: none;
    opacity: 0.6;
    transition: 0.3s ease;
  }

  .titleNoticia a, .titleAgenda a {
    transition: 0.3s ease;
  }

  .dirty {
  border-color: #5A5;
  background: #EFE;
  }

  .dirty:focus {
    outline-color: #8E8;
  }

  .error {
    border-color: red;
    background: #FDD;
  }

  .error:focus {
    outline-color: #F99;
  }

  .form-group--alert, .form-group--error {
    animation-name: shakeError;
    animation-fill-mode: forward;
    animation-duration: .6s;
    animation-timing-function: ease-in-out;
}

@keyframes shakeError {
  0% {
    transform: translateX(0);
  }
  15% {
      transform: translateX(0.375rem);
  }
  30% {
      transform: translateX(-0.375rem);
  }
  45% {
      transform: translateX(0.375rem);
  }
  60% {
      transform: translateX(-0.375rem);
  }
  75% {
      transform: translateX(0.375rem);
  }

  90% {
      transform: translateX(-0.375rem);
  }
  100% {
      transform: translateX(0);
  }
}

input.form-group--error, textarea.form-group--error, select.form-group--error {
  border-color: #f57f6c;
}

input.form-group--valid, textarea.form-group--valid, select.form-group--valid {
  border-color: green;
}

span.formError, div.formError {
  font-size: 0.75rem;
  line-height: 1;
  margin-left: 14px;
  margin-top: -1.6875rem;
  margin-bottom: 0.9375rem;
  color: #f57f6c;
}

 div.formError {
  margin-top: -0.2875rem;
 }

select.select_arrow3 {
  color: #999
}

select.select_arrow3 option {
  color: white;
}

select.select_arrow3 option:first-child {
  color: #999;
}

span.terms--error {
  border: 1px solid #f57f6c;
}

.typo__p {
  color:white;
}

.contenidoFiltros select, .contenidoFormulario select  {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
}

.flex-container {
  display: flex;
  flex-wrap: wrap
}
.minortext {
	color: rgb(196, 189, 189);
}
p {
  margin-top: 0;
  margin-bottom: 0rem;
  font-size: 12px;
}
</style>